<!-- eslint-disable no-restricted-syntax -->
<template>
  <section
    v-if="Object.values(getAllPackagesAvailable)"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div
      v-if="loading"
      class="loader-overlay"
    >
      <div
        class="spinner"
      >
        <b-spinner
          key="primary"
          style="width: 4rem; height: 4rem;"
          label="Large Spinner"
          variant="primary"
          xx-large
        />
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="text-center mt-5">
      <h1 class="mt-5">
        Pricing Plans
      </h1>
    </div>
    <b-tabs
      class="tabClass"
      align="center"
    >
      <b-tab
        active
        title="Monthly"
        class="media-card"
      >
        <!--/ title text and switch button -->
        <!-- pricing plan cards -->
        <b-row class="pricing-card">
          <b-col
            offset-sm-2
            sm="10"
            md="12"
            offset-lg="2"
            lg="10"
            class="mx-auto"
          >
            <b-row>
              <b-row class="d-flex justify-content-center">
                <span
                  v-for="(fullPackage, index ) in Object.values(getAllPackagesAvailable)"
                  :key="index"
                  :class="loader ? 'mr-3 mb-3': null"
                >
                  <b-overlay
                    v-if="fullPackage.id === 1 || fullPackage.id === 2 || fullPackage.id === 3"
                    :show="showOverlay(index, fullPackage.slug,fullPackage.id)"
                  >
                    <template #overlay>
                      <div />
                    </template>
                    <b-skeleton-img
                      v-if="loader"
                      no-aspect
                    />
                    <b-card
                      v-else
                      class="m-1"
                    >
                      <div class="d-flex flex-column align-items-center justify-content-center">
                        <!-- img -->
                        <div>
                          <b-img
                            src="@/assets/images/simiicons/device.svg"
                            class="mb-1 mt-1 d-block"
                            alt="basic svg img"
                          />
                        </div>
                        <!--/ img -->
                        <h3 class="mb-0">{{ fullPackage.name }}</h3>
                      </div>
                      <!-- annual plan -->
                      <div
                        class="d-flex align-items-center justify-content-center annual-plan"
                      >
                        <div
                          class="plan-price mt-2"
                        >
                          <sup class="font-medium-1 font-weight-bold text-primary">AUD</sup>
                          <span class="pricing-basic-value font-weight-bolder text-primary">{{ packagePrice(fullPackage) }}</span>
                          <sub
                            class="pricing-duration text-body font-medium-1 font-weight-bold"
                          >/month</sub>
                        </div>
                      </div>
                      <!--/ annual plan -->
                      <!-- plan benefit -->
                      <b-list-group
                        :class="`list-group-circle text-left pb-2 ${fullPackage.id === 1 || fullPackage.id === 6 ? 'mb-4' : 'none'}`"
                        :style="`${fullPackage.slug ==='power-saver'? 'min-height: 475px' : 'none'}`"
                      >
                        <b-list-group-item
                          v-for="(data,i) in fullPackage.id === 1 || fullPackage.id === 6 ? planFeatureListEnum.starterPlanfeaturesList : fullPackage.id === 2 || fullPackage.id === 7 ? planFeatureListEnum.multiPlanfeaturesList : fullPackage.id === 3 | fullPackage.id === 8 ? planFeatureListEnum.networkPlanfeaturesList : fullPackage.id === 5 ? planFeatureListEnum.advertiserPlanfeaturesList : planFeatureListEnum.featuresListPowerSaving"
                          :key="i"
                        >
                          {{ data }}
                        </b-list-group-item>
                      </b-list-group>

                      <!--sub members-->
                      <div
                        class="position-bottom-0 position-relative btns-class pl-2"
                      >

                        <div v-if="fullPackage.id === 3 || fullPackage.id === 8">
                          <b-dropdown
                            v-model="fullPackage.screenQuota "
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            :text="`No of screens ${fullPackage.screenQuota}`"
                            variant="outline-secondary"
                            menu-class="w-100"
                            right
                            block
                          >
                            <span
                              v-for="option in screens"
                              :key="option.key"
                            >
                              <b-dropdown-divider />
                              <b-dropdown-item @click="setValueOfScreens(index, option)">
                                {{ option.key }}
                              </b-dropdown-item>
                            </span>
                          </b-dropdown>
                        </div>
                        <div v-if="fullPackage.slug==='2-screens' || fullPackage.slug==='multi-plan-yearly'">
                          <b-dropdown
                            v-model="fullPackage.screenQuota "
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            :text="`No of screens ${fullPackage.screenQuota}`"
                            variant="outline-secondary"
                            menu-class="w-100"
                            right
                            block
                          >
                            <span
                              v-for="option in multiScreens"
                              :key="option.key"
                            >
                              <b-dropdown-divider />
                              <b-dropdown-item @click="setValueOfScreens(index, option)">
                                {{ option.key }}
                              </b-dropdown-item>
                            </span>
                          </b-dropdown>
                        </div>
                        <strong class="d-none">{{ group.quota }}</strong>
                        <!-- /sub members-->
                        <!-- buttons -->
                        <div>
                          <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            block
                            class="mt-2"
                            variant="primary"
                            :disabled="spinner"
                            @click="selectPackageFunc(fullPackage.id, index)"
                          >
                            Select
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </b-overlay>
                </span>
              </b-row>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="Yearly"
        class="media-card"
      >
        <!--/ title text and switch button -->
        <!-- pricing plan cards -->
        <b-row class="pricing-card">
          <b-col
            offset-sm-2
            sm="10"
            md="12"
            offset-lg="2"
            lg="10"
            class="mx-auto"
          >
            <b-row>
              <b-row class="d-flex justify-content-center">
                <span
                  v-for="(fullPackage, index ) in Object.values(getAllPackagesAvailable)"
                  :key="index"
                  :class="loader ? 'mr-3 mb-3': null"
                >
                  <b-overlay
                    v-if="fullPackage.id === 6 || fullPackage.id === 7 || fullPackage.id === 8"
                    :show="showOverlay(index, fullPackage.slug,fullPackage.id)"
                  >
                    <template #overlay>
                      <div />
                    </template>
                    <b-skeleton-img
                      v-if="loader"
                      no-aspect
                    />
                    <b-card
                      v-else
                      class="m-1"
                    >
                      <div class="d-flex flex-column align-items-center justify-content-center">
                        <!-- img -->
                        <div>
                          <b-img
                            src="@/assets/images/simiicons/device.svg"
                            class="mb-1 mt-1 d-block"
                            alt="basic svg img"
                          />
                        </div>
                        <!--/ img -->
                        <h3 class="mb-0">{{ fullPackage.name }}</h3>
                      </div>
                      <!-- annual plan -->
                      <div
                        class="d-flex align-items-center justify-content-center annual-plan"
                      >
                        <div
                          class="plan-price mt-2"
                        >
                          <sup class="font-medium-1 font-weight-bold text-primary">AUD</sup>
                          <span class="pricing-basic-value font-weight-bolder text-primary">{{ packagePrice(fullPackage) }}</span>
                          <sub
                            class="pricing-duration text-body font-medium-1 font-weight-bold"
                          >/month</sub>
                        </div>
                      </div>
                      <!--/ annual plan -->
                      <!-- plan benefit -->
                      <b-list-group
                        :class="`list-group-circle text-left pb-2 ${fullPackage.id === 1 || fullPackage.id === 6 ? 'mb-4' : 'none'}`"
                        :style="`${fullPackage.slug ==='power-saver'? 'min-height: 475px' : 'none'}`"
                      >
                        <b-list-group-item
                          v-for="(data,i) in fullPackage.id === 1 || fullPackage.id === 6 ? planFeatureListEnum.starterPlanfeaturesList : fullPackage.id === 2 || fullPackage.id === 7 ? planFeatureListEnum.multiPlanfeaturesList : fullPackage.id === 3 | fullPackage.id === 8 ? planFeatureListEnum.networkPlanfeaturesList : fullPackage.id === 5 ? planFeatureListEnum.advertiserPlanfeaturesList : planFeatureListEnum.featuresListPowerSaving"
                          :key="i"
                        >
                          {{ data }}
                        </b-list-group-item>
                      </b-list-group>

                      <!--sub members-->
                      <div
                        class="position-bottom-0 position-relative btns-class pl-2"
                      >

                        <div v-if="fullPackage.id === 3 || fullPackage.id === 8">
                          <b-dropdown
                            v-model="fullPackage.screenQuota "
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            :text="`No of screens ${fullPackage.screenQuota}`"
                            variant="outline-secondary"
                            menu-class="w-100"
                            right
                            block
                          >
                            <span
                              v-for="option in screens"
                              :key="option.key"
                            >
                              <b-dropdown-divider />
                              <b-dropdown-item @click="setValueOfScreens(index, option)">
                                {{ option.key }}
                              </b-dropdown-item>
                            </span>
                          </b-dropdown>
                        </div>
                        <div v-if="fullPackage.slug==='2-screens' || fullPackage.slug==='multi-plan-yearly'">
                          <b-dropdown
                            v-model="fullPackage.screenQuota "
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            :text="`No of screens ${fullPackage.screenQuota}`"
                            variant="outline-secondary"
                            menu-class="w-100"
                            right
                            block
                          >
                            <span
                              v-for="option in multiScreens"
                              :key="option.key"
                            >
                              <b-dropdown-divider />
                              <b-dropdown-item @click="setValueOfScreens(index, option)">
                                {{ option.key }}
                              </b-dropdown-item>
                            </span>
                          </b-dropdown>
                        </div>
                        <strong class="d-none">{{ group.quota }}</strong>
                        <!-- /sub members-->
                        <!-- buttons -->
                        <div>
                          <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            block
                            class="mt-2"
                            variant="primary"
                            :disabled="spinner"
                            @click="selectPackageFunc(fullPackage.id, index)"
                          >
                            Select
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </b-overlay>
                </span>
              </b-row>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <!--/ Delete Device Modal -->
    <DeleteDevice
      ref="delete-device-modal"
      :group-id="$route.params.id"
      @reload-members="initializeData"
    />
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BOverlay, BListGroup, BListGroupItem, BButton, BDropdown, BDropdownItem, BDropdownDivider, BSpinner, BSkeletonImg,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import SiteType from '@/common/enums/siteTypeEnum'
import screens from '@/common/enums/noOfScreenEnum'
import Packages from '@/common/enums/packagesEnum'
/* eslint-disable global-require */
import ApplicationType from '@/common/enums/applicationTypeEnum'
import { showToast, packagePrice } from '@/common/global/functions'
import PackageFeatures from '@/common/enums/packagesFeatureTypeEnum'
import DeleteDevice from '@/components/devices/DeleteDevice.vue'
import MemberQuota from '@/common/enums/memberQuotaEnum'
import planFeatureListEnum from '@/common/enums/planFeatureListEnum'

export default {
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSpinner,
    BSkeletonImg,
    DeleteDevice,
    BImg,
    BOverlay,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
  },
  async beforeRouteEnter(to, from, next) {
    // const userOwnGroup = await store.getters['user/getUserOwnCreatedGroup']
    const user = await store.getters['user/getUser']
    const isUserAdmin = await store.getters['user/isUserAdmin']
    if (isUserAdmin) {
      next({
        path: '/',
      })
      return
    }
    if (!isUserAdmin && !user.is_phone_number_verified) {
      next({
        name: 'phone-verification',
      })
      return
    }
    if (!isUserAdmin && !user.is_profile_completed) {
      next({
        name: 'complete-profile',
      })
      return
    }
    next()
  },

  data() {
    return {
      SiteType,
      Packages,
      packagePrice,
      screens,
      allMembers: [],
      selectedGroupId: null,
      loader: false,
      spinner: false,
      selectedScreen: [],
      status: 'monthly',
      monthlyPlanShow: true,
      memberValue: [],
      totalPrice: null,
      planFeatureListEnum,
      group: {
        planId: null,
        quota: null,
        price: null,
        packageName: '',
        features: [],
        description: null,
      },
      isUserNotPowerSaving: false,
      editGroupModal: false,
      MemberQuota,
      loading: false,
      isUserNotAgency: false,
      multiScreens: [
        { key: 2, value: 'No of Screens 2' },
        { key: 3, value: 'No of Screens 3' },
        { key: 4, value: 'No of Screens 4' },
        { key: 5, value: 'No of Screens 5' },
      ],
    }
  },
  computed: {
    getAllPackagesAvailable() {
      return this.$store.getters['user/getAllPackagesAvailable']
    },
    preSelectedGroupData() {
      return this.$store.getters['user/getCreateGroupData']
    },

  },
  async mounted() {
    this.loader = true
    this.$store.dispatch('user/setPackagesAvailable')
    this.$store.dispatch('user/setAllUserGroupIfNotPresent')
    this.selectedGroupId = this.$route.params.id
    await this.getAllMembersOfGroup()
    await this.$store.dispatch('device/getDevices', {
      groupId: this.$route.params.id,
    })
    this.loader = false
  },
  methods: {
    showOverlay(index, slug, id) {
      if (this.allMembers[0].user_has_group.package.plan.id === id) {
        if (slug === Packages.THREE_PLUS_SCREENS || slug === Packages.MULTI_PLAN_YEARLY || slug === Packages.TWO_SCREENS || slug === Packages.NETWORK_PLAN_YEARLY) {
          return false
        }
        return true
      }
      return false
    },
    async initializeData() {
      try {
        await Promise.all([
          this.$store.dispatch('user/setPackagesAvailable'),
          this.getAllMembersOfGroup(),
          this.$store.dispatch('device/getDevices', {
            groupId: this.$route.params.id,
          }),
        ])
        return
      } catch (error) {
        // Handle errors here
        console.error('Error occurred during data initialization:', error)
      }
    },
    openConfirmationModal() {
      this.$refs['delete-device-modal'].showDeleteDevice()
    },
    handleFeatureList(index) {
      packagePrice(this.getAllPackagesAvailable[index])
    },
    async getAllMembersOfGroup() {
      this.spinner = true
      try {
        const res = await this.$axios.get(`user-has-group/get-user-group-details/${this.selectedGroupId}`)
        const { data: resData } = res || {}
        const { data } = resData || {}
        this.allMembers = data || {}
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        showToast('Business Members', 'You are not authorized for this action!', 'danger')
        await store.dispatch('user/setAllUserGroupIfNotPresent', true)
        await this.$router.push('groups-listing')
      }
      if (this.allMembers[0]?.user_has_group?.package?.plan?.slug !== Packages.POWER_SAVER) {
        this.isUserNotPowerSaving = true
      } else {
        this.isUserNotPowerSaving = true
      }
      if (this.allMembers[0]?.user_has_group?.package?.plan?.slug !== Packages.AGENCY) {
        this.isUserNotAgency = true
      } else {
        this.isUserNotAgency = false
      }
      this.spinner = false
    },
    setValueOfScreens(index, screenValue) {
      const pack = this.getAllPackagesAvailable[index]
      if (screenValue.key === this.allMembers[0].user_has_group.device_quota && this.allMembers[0].user_has_group.package.plan.id === pack.id) {
        if (pack.id === 2 || pack.id === 7) {
          pack.screenQuota = 5
        } else {
          pack.screenQuota = 10
        }
        packagePrice(pack)
        this.$swal('You cannot select the same no of screens')
      } else {
        pack.screenQuota = screenValue.key
        packagePrice(pack)
      }
    },
    async selectPackageFunc(id, index) {
      const selectedScreen = this.getAllPackagesAvailable[index]
      const selectedGroup = this.allMembers[0]
      if (selectedGroup.user_has_group.device_quota > selectedScreen.screenQuota && selectedGroup.device_count > selectedScreen.screenQuota) {
        // this.openConfirmationModal()
        this.$swal('You cannot downgrade the plan. Please contact Admin')
      } else if (selectedGroup.user_has_group.device_quota === selectedScreen.screenQuota) {
        this.$swal('You cannot select the same no of screens')
      } else {
        let isChecked = false
        selectedScreen.plan_has_feature.forEach(planHasFeature => {
          if (planHasFeature.feature.slug !== PackageFeatures.DEVICES) {
            if (planHasFeature.feature.isSelected) {
              isChecked = true
            }
          }
        })
        if (isChecked) {
          await this.$store.commit('user/SET_CREATE_GROUP_DATA', { ...selectedScreen })
          await this.$store.commit('user/SET_CREATE_GROUP_DATA', { ...this.preSelectedGroupData })
          const featureIds = selectedScreen.plan_has_feature
            .filter(plan => plan?.feature?.isSelected)
            .map(plan => plan.feature.id)
          const packageData = {
            name: selectedScreen?.name,
            planId: selectedScreen?.id,
            quota: selectedScreen.screenQuota,
            device_quota: selectedScreen.screenQuota,
            selected_features: featureIds,
            lookup_key: selectedScreen.slug,
            quantity: selectedScreen.screenQuota,
          }
          await this.updateBusinessPlan(packageData, selectedScreen)
        } else {
          showToast('Pricing Plans', 'Please select atleast one feature', 'danger', 4000)
        }
      }
    },
    filterFeatureName(name, quantity) {
      if (name === 'Site' && quantity === SiteType.SITE) {
        return 'Site'
      }
      if (name === 'Site' && quantity === SiteType.MULTI_SITE) {
        return 'Multi Site'
      }
      return name
    },
    hasLauncherFeature(features) {
      let found = false
      found = features.some(el => el.feature.application_type === ApplicationType.SIMI_LAUNCHER)
      return found
    },
    updateBusinessPlan(packageData, selectedScreen) {
      this.$swal({
        title: '<span class="font-weight-bolder">Update Plan ?</span>',
        icon: 'question',
        html: `Are you sure want to Upgade plan to ${selectedScreen.name}`,
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Upgrade',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        width: '550px',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          icon: 'primary',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.loading = true
            try {
              const resp = await this.$axios.put('package/upgrade-and-downgrade-package', packageData, {
                headers: {
                  userHasGroupId: this.$route.params.id,
                },
              })
              if (resp.data.statusCode === 200) {
                showToast('Update Plans', resp.data.message, 'success', 4000)
                await this.$router.push('/groups-listing')
              }
            } catch (e) {
              console.error(e)
              showToast('Update Plans', e.message, 'danger', 4000)
            } finally {
              this.loading = false
            }
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$router.back()
          }
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
#pricing-plan{
  .dropdown-item {
    padding: 0.05rem 1.28rem !important;
  }
  .card-body{
    margin: 1rem !important;
    padding: 1rem !important;
    position: relative;
    margin-top: 0px !important;
  }
  .card{
    min-height:550px !important;
    min-width: 300px !important;
  }
  .b-skeleton-img{
    min-height:450px !important;
    min-width: 300px !important;
  }
  .row{
    margin: 0px !important;
  }

  .btns-class{
    width: 100%;
    margin: -15px;
    margin-bottom: 15px;
  }
   .list-group-item{
     padding: 0.5rem .25rem !important;
     padding-left: 1.5rem
   }
 .list-group.list-group-circle .list-group-item{
    padding-left: 1.5rem !important;
  }
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
}
</style>
